jQuery(document).ready(function ($) {
    $(".anchor-link[href^='#']").on('click', function (e) {
        e.preventDefault();

        var hash = this.hash;

        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 'fast')
    })
});