jQuery(document).ready(function ($) {
    var $processos = $('.box-carousel-processos .swiper-container');

    if (!$processos.length) return

    $processos.each(function (index, element) {
        new Swiper(element, {
            autoplay: {
                delay: 7000,
            },
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 10,
            speed: 550,
            navigation: {
                nextEl: $(element).parent().find('.carousel-nav.next')[0],
                prevEl: $(element).parent().find('.carousel-nav.prev')[0],
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30
                }
            }
        });
    });
});