jQuery(document).ready(function ($) {
    var $institucional = $('.box-carousel-institucional .swiper-container');

    if (!$institucional.length) return

    $institucional.each(function (index, element) {
        new Swiper(element, {
            autoplay: {
                delay: 7000,
            },
            slidesPerView: 1,
            spaceBetween: 10,
            speed: 550,
            navigation: {
                nextEl: $(element).parent().find('.carousel-nav.next')[0],
                prevEl: $(element).parent().find('.carousel-nav.prev')[0],
            },
            breakpoints: {
                576: {
                    slidesPerView: 'auto',
                },
            }
        });
    });
});