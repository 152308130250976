jQuery(document).ready(function ($) {
    var $slides = $('.section-slide .swiper-container');

    if (!$slides.length) return

    $slides.each(function (index, element) {
        new Swiper(element, {
            autoplay: {
                delay: 7000,
            },
            slidesPerView: 1,
            speed: 550,
            navigation: {
                nextEl: $(element).parent().find('.slide-nav.next')[0],
                prevEl: $(element).parent().find('.slide-nav.prev')[0],
            },
            pagination: {
                el: $(element).parent().find('.slide-pagination')[0],
                clickable: true,
                dynamicBullets: true,
            },
        });
    });
});