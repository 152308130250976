jQuery(document).ready(function ($) {
    var $fancyboxgallery = $('[data-fancybox^="gallery-"]');

    if (!$fancyboxgallery.length) return

    // $.fancybox.defaults.hash = false;
    Fancybox.defaults.infinite = 0;
    Fancybox.defaults.Hash = false;


    Fancybox.bind('[data-fancybox^="gallery-"]');

    // $('[data-fancybox^="group-]').each(function () {
    //     $(this).fancybox({
    //         loop: false
    //     });
    // });
});

jQuery(document).ready(function ($) {
    var $fancyboxvideo = $('[data-fancybox-video]');

    if (!$fancyboxvideo.length) return

    Fancybox.bind('[data-fancybox-video]', {
        animated: false,
        showClass: false,
        hideClass: false,

        click: false,

        dragToClose: false,

        Image: {
            zoom: false,
        },

        Toolbar: {
            display: [
                {
                    id: "counter",
                    position: "center"
                },
                "close"
            ],
        },
    });

    // $('[data-fancybox-video]').each(function () {
    //     $(this).fancybox({
    //         youtube: {
    //             showinfo: 0
    //         },
    //         vimeo: {
    //             color: 'f00'
    //         }
    //     });
    // });
});