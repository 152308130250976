jQuery(document).ready(function ($) {
    var $clientes = $('.box-carousel-clientes .swiper-container');

    if (!$clientes.length) return

    $clientes.each(function (index, element) {
        new Swiper(element, {
            autoplay: {
                delay: 7000,
            },
            slidesPerView: 1,
            spaceBetween: 10,
            speed: 550,
            navigation: {
                nextEl: $(element).parent().find('.carousel-nav.next')[0],
                prevEl: $(element).parent().find('.carousel-nav.prev')[0],
            },
        });
    });
});